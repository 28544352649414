<template>
	<div class="navbar">
		<div
			class="navbar-item"
			@click="toggleItem(index, item)"
			v-for="(item, index) in listItem"
			:key="index"
			:class="active === index ? 'active' : ''"
		>
			<i class="iconfont" :class="active === index ? item.activeIcon : item.icon"></i>
			<div>{{ item.title }}</div>
		</div>
	</div>
</template>
<script>
export default {
	components: {},
	data() {
		return {
			active: 0,
			listItem: [
				{
					title: '首页',
					icon: 'icon-shouye_home01',
					activeIcon: 'icon-shouye_home02',
					url: '/',
				},
				{
					title: '预约管理',
					icon: 'icon-shouye_booking01',
					activeIcon: 'icon-shouye_booking02',
					url: '/SmartClassroom/AppointmentList',
				},
				{
					title: '控制台',
					icon: 'icon-shouye_control01',
					activeIcon: 'icon-shouye_control02',
					url: '/SmartClassroom/Controls',
				},
				{
					title: '我的',
					icon: 'icon-shouye_my01',
					activeIcon: 'icon-shouye_my02',
					url: '/SmartClassroom/Mine',
				},
			],
		}
	},

	methods: {
		toggleItem(index, item) {
			this.active = index
			this.$router.replace({
				path: item.url,
				query: { active: this.active, showIcon: true },
			})
			console.log(this.$route)
		},
	},
	computed: {},
	mounted() {
		if (this.$route.query.active) {
			this.active = parseInt(this.$route.query.active)
		}
		// else {
		//   this.$router.replace({
		//     path: this.$router.fullpath,
		//     query: { active: this.active },
		//   });
		// }
	},
}
</script>
<style lang="scss">
@import '../style/public.scss';
.navbar {
	width: 100%;
	height: 62px;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 999;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	background: #fff;
	&-item.active {
		> i {
			color: $titleColor;
		}
		> div {
			color: $titleColor;
		}
	}
	&-item {
		width: 25%;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		color: $defocus;
		> i {
			margin-bottom: 7px;
			font-size: 20px;
		}
		> div {
			font-size: 12px;
			transform: scale(0.9);
		}
	}
}
</style>
