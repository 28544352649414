<template>
  <div class="index-page">
    <router-view />
    <Navbar v-show="$route.meta.showIcon || $route.query.showIcon"></Navbar>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
export default {
  name: "Index",
  components: {
    Navbar,
  },
  data() {
    return {
      showIcon: true,
    };
  },

  methods: {},
  mounted() {
    // this.$store.state.token;
  },
};
</script>
<style lang="scss">
.index-page {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>